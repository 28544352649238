import { defineI18nConfig } from '#imports'
import fr from './locales/fr'
import en from './locales/en'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'fr',
  messages: {
    en,
    fr
  }
}))
